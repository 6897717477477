import { Flex, HStack, Input, Stack, Text } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  addOrUpdateBusinessAddress,
  ORGANIZATION_SERVICE_QUERY_KEYS,
  useOrgDetailsQuery,
} from 'apis/organizations-apis';
import { AddressSuggestionPopover } from 'components/Address/AddressSuggestionPopover';
import FormErrorMessage from 'components/error/FormError';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { Field } from 'components/ui/field';
import { NativeSelectField, NativeSelectRoot } from 'components/ui/native-select';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useLocationData } from 'hooks/useLocationData';
import { useOrg } from 'hooks/useOrg';
import { useEffect, useState } from 'react';
import { SuggestedAddress, UpdateAddressPromptValidationSchema } from 'types/address';
import { LocationOptionType } from 'types/location';
import { replaceNullWithEmptyString } from 'utils';

type updateBusinessAddressPrompt = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

export const BusinessAddressPrompt = ({ isOpen, onClose, onSuccess }: updateBusinessAddressPrompt) => {
  const { orgId } = useOrg();
  const queryClient = useQueryClient();
  const { handleSuccessNotification } = useHandleNotification();

  const { countries, getStates } = useLocationData();
  const [stateOptions, setStateOptions] = useState<LocationOptionType[]>([]);

  const { data: orgDetails } = useOrgDetailsQuery(orgId);

  const { mutate: addBusinessAddress, isPending: isAddingBusinessAddress } = useMutation({
    mutationFn: (payload: any) => {
      return addOrUpdateBusinessAddress(orgId, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [...ORGANIZATION_SERVICE_QUERY_KEYS.details(orgId)] });
      onCloseModal();
      handleSuccessNotification('Business Address added successfully');
      onSuccess();
    },
  });

  const { values, setValues, setFieldValue, handleChange, handleSubmit, resetForm, errors, touched } = useFormik({
    initialValues: {
      business_name: '',
      company_address_1: '',
      company_address_2: '',
      company_city: '',
      company_county: '',
      company_state: '',
      company_postal_code: '',
      company_country_code: '',
    },
    validationSchema: UpdateAddressPromptValidationSchema,
    onSubmit: values => {
      addBusinessAddress({ ...values });
    },
  });

  const addressToValidate = {
    city: values.company_city,
    county: values.company_county,
    state: values.company_state,
    postal_code: values.company_postal_code,
  };

  const applySelectedAddress = (address: SuggestedAddress) => {
    setValues({
      ...values,
      company_city: address.city as string,
      company_county: address.county as string,
      company_state: address.state as string,
      company_postal_code: address.postal_code as string,
    });
  };

  const onCloseModal = () => {
    resetForm();
    onClose();
  };

  useEffect(() => {
    const fetchStates = async () => {
      const states = await getStates(values.company_country_code ?? '');
      setStateOptions(states);
    };
    fetchStates();
  }, [getStates, values.company_country_code]);

  useEffect(() => {
    if (orgDetails) {
      const sanitizedValues = replaceNullWithEmptyString(orgDetails);
      setValues({
        business_name: sanitizedValues.business_name,
        company_address_1: sanitizedValues.company_address_1,
        company_address_2: sanitizedValues.company_address_2,
        company_city: sanitizedValues.company_city,
        company_county: sanitizedValues.company_county,
        company_state: sanitizedValues.company_state,
        company_postal_code: sanitizedValues.company_postal_code,
        company_country_code: sanitizedValues.company_country_code,
      });
    }
  }, [orgDetails]);

  return (
    <>
      <DialogRoot size={'md'} open={isOpen}>
        <DialogBackdrop />
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Please provide business address before importing</DialogTitle>
          </DialogHeader>
          <DialogBody>
            <Stack>
              <Text>
                In order to calculate sales tax rates for origin and destination based jurisdictions we need to know
                your company address
              </Text>
              <Field
                label="Legal Business Name"
                invalid={!!(errors.business_name && touched.business_name)}
                errorText={errors.business_name}
                required
              >
                <Input
                  id="business_name"
                  type="business_name"
                  name="business_name"
                  value={values.business_name}
                  onChange={handleChange}
                />
              </Field>
              <HStack>
                <Field
                  label="Country"
                  invalid={!!(errors.company_country_code && touched.company_country_code)}
                  errorText={errors.company_country_code}
                  required
                >
                  <NativeSelectRoot>
                    <NativeSelectField
                      name="company_country_code"
                      placeholder="Select Country"
                      onChange={e => setFieldValue('company_country_code', e.target.value)}
                      value={values.company_country_code}
                    >
                      {countries.map(({ label, value }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </NativeSelectField>
                  </NativeSelectRoot>
                </Field>
                <Field
                  label="State"
                  invalid={!!(errors.company_state && touched.company_state)}
                  errorText={errors.company_state}
                  required
                >
                  <NativeSelectRoot>
                    <NativeSelectField
                      name="company_state"
                      placeholder="Select State"
                      onChange={handleChange}
                      value={values.company_state}
                    >
                      {stateOptions.map(({ label, value }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </NativeSelectField>
                  </NativeSelectRoot>

                  {errors.company_state && touched.company_state && <FormErrorMessage message={errors.company_state} />}
                </Field>
              </HStack>
              <HStack>
                <Field
                  label="City"
                  invalid={!!(errors.company_city && touched.company_city)}
                  errorText={errors.company_city}
                  required
                >
                  <Input
                    id="company_city"
                    type="company_city"
                    name="company_city"
                    value={values.company_city}
                    onChange={handleChange}
                  />
                </Field>
                <Field
                  label="Postal Code"
                  invalid={!!(errors.company_postal_code && touched.company_postal_code)}
                  errorText={errors.company_postal_code}
                  required
                >
                  <Input
                    id="company_postal_code"
                    type="company_postal_code"
                    name="company_postal_code"
                    value={values.company_postal_code}
                    onChange={handleChange}
                  />
                </Field>
              </HStack>
              <HStack>
                <Field
                  label="Address Line 1"
                  invalid={!!(errors.company_address_1 && touched.company_address_1)}
                  errorText={errors.company_address_1}
                  required
                >
                  <Input
                    id="company_address_1"
                    type="company_address_1"
                    name="company_address_1"
                    value={values.company_address_1}
                    onChange={handleChange}
                  />
                </Field>
                <Field label="Address Line 2">
                  <Input
                    id="company_address_2"
                    type="company_address_2"
                    name="company_address_2"
                    value={values.company_address_2}
                    onChange={handleChange}
                  />
                </Field>
              </HStack>
              <Flex justify={'flex-start'}>
                {isOpen && values && (
                  <AddressSuggestionPopover
                    address={addressToValidate}
                    onUpdateAddress={applySelectedAddress}
                    isParentOpen={isOpen}
                  />
                )}
              </Flex>
            </Stack>
          </DialogBody>
          <DialogFooter>
            <Flex gap={2}>
              <Button variant={'outline'} color={'secondary'} onClick={onCloseModal}>
                Cancel
              </Button>
              <Button
                variant={'solid'}
                color={'primary'}
                width={'90px'}
                onClick={() => handleSubmit()}
                loading={isAddingBusinessAddress}
              >
                Submit
              </Button>
            </Flex>
          </DialogFooter>
        </DialogContent>
      </DialogRoot>
    </>
  );
};
