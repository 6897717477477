import { DrawerBackdrop, Flex, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { dataSourceQueryKeys, getFileUploadHistory } from 'apis/data-sources-apis';
import { ArrowLeft } from 'components/icons';
import { PaginationButtons } from 'components/pagination/pagination-buttons';
import { KDataTable } from 'components/table/data-table';
import { TableEmptyState } from 'components/table-empty-state/table-empty-state';
import { DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerRoot } from 'components/ui/drawer';
import { Skeleton } from 'components/ui/skeleton';
import { useOrg } from 'hooks/useOrg';
import { useTableFilters } from 'hooks/useTableFilters';

import { HistoryTableRow } from './table-row';

type FileUploadHistoryProps = {
  isOpen: boolean;
  onClose: () => void;
};
const TABLE_HEAD = ['Name', 'Rows', 'Transactions', 'Uploaded Date', 'Status'];

export const FileUploadHistory = ({ isOpen, onClose }: FileUploadHistoryProps) => {
  const { orgId } = useOrg();
  const { page, size, setFilters } = useTableFilters();

  const { data: history, isPending: isLoading } = useQuery({
    queryKey: [...dataSourceQueryKeys.fileHistory(), orgId, page, size],
    queryFn: async () => {
      const { data } = await getFileUploadHistory(orgId, { page, size });
      return data;
    },
  });

  const historyData = history?.items || [];
  const totalPages = history?.pages ?? 0;
  const isDataEmpty = !historyData || historyData?.length === 0;

  return (
    <DrawerRoot
      onOpenChange={({ open }) => {
        if (!open) {
          onClose();
        }
      }}
      open={isOpen}
      size={'xl'}
    >
      <DrawerBackdrop />
      <DrawerContent>
        <DrawerHeader>
          <Flex alignItems={'center'} gap={2}>
            <ArrowLeft focusable={true} cursor={'pointer'} onClick={onClose} />
            <Text fontSize={'20px'} fontWeight={500}>
              History
            </Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          {isDataEmpty && !isLoading && <TableEmptyState tableName="File Upload History" />}
          {isLoading && <Skeleton />}
          {!isLoading && !isDataEmpty && (
            <KDataTable headers={TABLE_HEAD} defaultVisibleColumns={TABLE_HEAD}>
              {historyData.map(history => (
                <HistoryTableRow key={history.id} history={history} />
              ))}
            </KDataTable>
          )}
        </DrawerBody>
        <DrawerFooter justifyContent={'center'}>
          {!isDataEmpty && (
            <PaginationButtons size={size} currentPage={page} totalPages={totalPages} setFilters={setFilters} />
          )}
        </DrawerFooter>
      </DrawerContent>
    </DrawerRoot>
  );
};
