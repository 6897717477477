import { createIcon } from '@chakra-ui/react';

export const AmazonSellerCentralIcon = createIcon({
  displayName: 'AmazonSellerCentralIcon',
  path: (
    <>
      <rect width="200" height="200" fill="white" />
      <rect y="1" width="50" height="49.5798" fill="url(#pattern0_amazon_sc)" />
      <defs>
        <pattern id="pattern0_amazon_sc" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_amazon_sc" transform="matrix(0.00282371 0 0 0.00284764 0 0)" />
        </pattern>
        <image id="image0_amazon_sc" width="354" height="351" xlinkHref="https://logodix.com/logo/803257.png" />
      </defs>
    </>
  ),
  defaultProps: {
    viewBox: '0 0 50 50',
  },
});
