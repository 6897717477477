import { ApideckVault } from '@apideck/vault-js';
import { Card, Flex, HStack, Icon, SimpleGrid, Stack, Text, VStack } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  CONNECTIONS_STATE_KEY,
  createApiDeckSession,
  getConnections,
  updateApiDeckConnections,
  updateConnection,
} from 'apis/connections';
import { updateTaxCalculation } from 'apis/data-sources-apis';
import { KebabIcon } from 'components/icons';
import { Button } from 'components/ui/button';
import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from 'components/ui/menu';
import { Skeleton } from 'components/ui/skeleton';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { useState } from 'react';
// import { MdWarningAmber } from 'react-icons/md';
import { SourceEnum } from 'schema/types-schema.d';
import { ApiResponse, ConnectionInstance, ConnectionStatus } from 'types/shared-types';

import { ArchiveConnection } from './archive-modal';
import ConnectionStateBadge from './connection-badge';
import { AmazonSellerCentralIcon } from './integrations/tabs/icons/amazon';
import { BigcommerceIcon } from './integrations/tabs/icons/bigcommerce';
import { ChargebeeIcon } from './integrations/tabs/icons/chargebee';
import { DeelIcon } from './integrations/tabs/icons/deel';
import { QuickbooksIcon } from './integrations/tabs/icons/quickbooks';
import { RipplingIcon } from './integrations/tabs/icons/rippling';
import { ShopifyIcon } from './integrations/tabs/icons/shopify';
import { StripeIcon } from './integrations/tabs/icons/stripe';
import { WooCommerceIcon } from './integrations/tabs/icons/woocommerce';

interface ApideckMap {
  unifiedApi: string;
  serviceId: string;
}

const TaxCalculationSupportedSources = [SourceEnum.STRIPE, SourceEnum.BIGCOMMERCE, SourceEnum.QUICKBOOKS];

const ApideckMapping = {
  BIGCOMMERCE: { serviceId: 'bigcommerce', unifiedApi: 'ecommerce' },
  QUICKBOOKS: { serviceId: 'quickbooks', unifiedApi: 'accounting' },
  WOOCOMMERCE: { serviceId: 'woocommerce', unifiedApi: 'ecommerce' },
  AMAZON: { serviceId: 'amazon-seller-central', unifiedApi: 'ecommerce' },
};

const integrations = [
  {
    source: 'BIGCOMMERCE',
    title: 'BigCommerce',
    subtitle: 'Enterprise ecommerce, simplified.',
    logo: <BigcommerceIcon size="xl" />,
  },
  {
    source: 'CHARGEBEE',
    title: 'Chargebee',
    subtitle: 'SaaS for Revenue Growth Management.',
    logo: <ChargebeeIcon size="2xl" />,
  },
  {
    source: 'QUICKBOOKS',
    title: 'Quickbooks',
    subtitle: 'Accounting Software & Solutions.',
    logo: <QuickbooksIcon />,
  },
  {
    source: 'SHOPIFY',
    title: 'Shopify',
    subtitle: 'eCommerce Platform for All Businesses.',
    logo: <ShopifyIcon />,
  },
  {
    source: 'STRIPE',
    title: 'Stripe',
    subtitle: 'Financial Infrastructure for the Internet.',
    logo: <StripeIcon />,
  },
  {
    source: 'WOOCOMMERCE',
    title: 'WooCommerce',
    subtitle: 'Open-source eCommerce toolkit.',
    logo: <WooCommerceIcon />,
  },
  {
    source: 'DEEL',
    title: 'Deel',
    subtitle: 'Deel - The Future of Global HR.',
    logo: <DeelIcon />,
  },
  {
    source: 'RIPPLING',
    title: 'Rippling',
    subtitle: 'Streamline HR and IT management.',
    logo: <RipplingIcon />,
  },
  {
    source: 'AMAZON',
    title: 'Amazon',
    subtitle: 'Amazon',
    logo: <AmazonSellerCentralIcon />,
  },
];

export const ManageIntegrations = () => {
  const { orgId } = useOrg();
  const { handleSuccessNotification } = useHandleNotification();
  const queryClient = useQueryClient();
  const [confirmArchive, setConfirmArchive] = useState<string | null>(null);

  const { isPending, data } = useQuery<
    ApiResponse<ConnectionInstance>,
    unknown,
    ApiResponse<ConnectionInstance> & { filteredConnections: ConnectionInstance[] }
  >({
    queryKey: [CONNECTIONS_STATE_KEY, orgId],
    queryFn: async () => {
      const { data } = await getConnections(orgId);
      return data;
    },
    select: data => {
      return {
        ...data,
        filteredConnections: data.items.filter(connection => connection.status !== 'ARCHIVED'),
      };
    },
    enabled: !!orgId,
  });

  const filteredConnections = data?.filteredConnections || [];
  const isDataEmpty = !data || filteredConnections.length === 0;

  const { mutate } = useMutation({
    mutationFn: (payload: { connection_id: string; status: string }) => {
      return updateConnection(payload.connection_id, orgId, { status: payload.status });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CONNECTIONS_STATE_KEY] });
      handleSuccessNotification('Connection successfully updated');
    },
  });

  const handleUpdate = (connection_id: string, status: ConnectionStatus) => {
    mutate({ connection_id, status });
  };

  const onApideckDelete = async (connection_id: string) => {
    // Deleting from Apideck Vault archives a connection
    updateApiDeckConnections(connection_id, orgId, 'ARCHIVED');
  };

  const handleApiDeckSession = async (connection_id: string, sourcemap: ApideckMap) => {
    try {
      const response = await createApiDeckSession(orgId, connection_id);
      const responseData = response.data;
      const sessionToken = responseData.data['session_token'];

      ApideckVault.open({
        token: sessionToken,
        unifiedApi: sourcemap.unifiedApi,
        serviceId: sourcemap.serviceId,
        onConnectionDelete: () => onApideckDelete(connection_id),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const { mutate: updateTaxCalculationMutate } = useMutation({
    mutationFn: (payload: { connection_id: string; tax_calculation_enabled: boolean }) => {
      return updateTaxCalculation(orgId, payload.connection_id, payload.tax_calculation_enabled);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CONNECTIONS_STATE_KEY] });
      handleSuccessNotification('Tax calculation successfully updated');
    },
  });

  const handleDisableTaxCalculation = (
    source: string,
    external_id: string,
    tax_calculation_enabled: boolean,
    connection_id: string
  ) => {
    if (source === SourceEnum.BIGCOMMERCE) {
      window.open(`https://store-${external_id}.mybigcommerce.com/manage/app/53480`, '_blank');
    } else if (source === SourceEnum.STRIPE || source === SourceEnum.QUICKBOOKS) {
      updateTaxCalculationMutate({ connection_id, tax_calculation_enabled: !tax_calculation_enabled });
    }
  };

  const getMenuOptions = ({
    id: connection_id,
    status,
    source,
    external_id,
    tax_calculation_enabled,
    tax_calculation_ready,
  }: ConnectionInstance) => {
    let menuOptions = null;
    const active = status === ConnectionStatus.ACTIVE;
    const inactive = status === ConnectionStatus.INACTIVE;

    const showEditButton = source in ApideckMapping;

    if (active) {
      menuOptions = (
        <>
          {source === 'CHARGEBEE' && (
            <MenuItem
              value={SourceEnum.CHARGEBEE}
              onClick={() =>
                window.open(`https://${external_id}.chargebee.com/third_party/tax_providers/kintsugi`, '_blank')
              }
            >
              Install Kintsugi App
            </MenuItem>
          )}
          {showEditButton ? (
            <MenuItem
              value="edit"
              onClick={() => handleApiDeckSession(connection_id, ApideckMapping[source as keyof typeof ApideckMapping])}
            >
              Edit
            </MenuItem>
          ) : null}
          {TaxCalculationSupportedSources.includes(source) && tax_calculation_ready ? (
            <MenuItem
              value={source}
              onClick={() => handleDisableTaxCalculation(source, external_id, tax_calculation_enabled, connection_id)}
            >
              {tax_calculation_enabled ? 'Disable Tax Collection' : 'Enable Tax Collection'}
            </MenuItem>
          ) : null}
          <MenuItem value="update" onClick={() => handleUpdate(connection_id, ConnectionStatus.INACTIVE)}>
            Deactivate
          </MenuItem>
        </>
      );
    } else if (inactive) {
      menuOptions = (
        <>
          <MenuItem value="activate" onClick={() => handleUpdate(connection_id, ConnectionStatus.ACTIVE)}>
            Activate
          </MenuItem>
          <MenuItem
            value="archive"
            onClick={() => {
              setConfirmArchive(connection_id);
            }}
          >
            Archive
          </MenuItem>
        </>
      );
    }

    return menuOptions;
  };

  // const handleUpdateConnection = ({ source, external_id }: ConnectionInstance) => {
  //   if (source === SourceEnum.SHOPIFY) {
  //     window.open(`https://admin.shopify.com/store/${external_id}/apps/kintsugi-1`, '_blank');
  //   }
  // };

  if (isPending) {
    return <Skeleton height="200px" width="full" mt={4}></Skeleton>;
  }

  return (
    <Stack>
      <Text mt={4} mb={2} fontWeight={500}>
        Manage Integrations
      </Text>{' '}
      {isDataEmpty ? (
        <Text fontSize="sm">You have yet to set up a connection</Text>
      ) : (
        <SimpleGrid maxWidth={'1244px'} minChildWidth="sm" gap={6}>
          {filteredConnections?.map((connection, id) => {
            const integration = integrations.find(intg => intg.source === connection.source);
            if (!integration) return null;
            return (
              <Card.Root
                key={id}
                py={4}
                pl={4}
                pr={2}
                // borderColor={connection.needs_update ? 'orange.500' : undefined}
                // borderWidth={connection.needs_update ? '1.5px' : undefined}
                gap={4}
                width={'400px'}
              >
                <Flex justify={'space-between'} width={'100%'}>
                  <Icon w="50px" h="50px">
                    {integration?.logo}
                  </Icon>
                  <HStack>
                    {/* {connection.needs_update && (
                      <Button
                        colorPalette="orange"
                        variant="outline"
                        onClick={() => handleUpdateConnection(connection)}
                      >
                        <MdWarningAmber />
                        Update Connection
                      </Button>
                    )} */}
                    {connection.status !== 'ARCHIVED' && (
                      <MenuRoot>
                        <MenuTrigger asChild>
                          <Button variant={'transparent-with-icon'} boxSize={'24px'} p={0}>
                            <KebabIcon />
                          </Button>
                        </MenuTrigger>
                        <MenuContent>{getMenuOptions(connection)}</MenuContent>
                      </MenuRoot>
                    )}
                  </HStack>
                </Flex>
                <VStack alignItems="flex-start" justify={'center'} gap={2}>
                  <HStack gap={2}>
                    <Text fontSize={'18px'} fontWeight={'500'}>
                      {connection.source === SourceEnum.SHOPIFY && connection.store_name
                        ? connection.store_name
                        : integration?.title}
                    </Text>
                    <ConnectionStateBadge status={connection.status} style={{ width: '59px' }} />
                  </HStack>
                  <Text fontSize={'sm'} color={'#4B5169'}>
                    {connection.source === SourceEnum.SHOPIFY && connection.external_id
                      ? `ID: ${connection.external_id}`
                      : integration?.subtitle}
                  </Text>
                </VStack>
              </Card.Root>
            );
          })}
        </SimpleGrid>
      )}
      {confirmArchive && (
        <ArchiveConnection
          connectionId={confirmArchive}
          onClose={() => {
            setConfirmArchive(null);
          }}
        />
      )}
    </Stack>
  );
};
